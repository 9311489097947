import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  _Any: any;
  _FieldSet: any;
};

export type AddLineItemAction = {
  quantity?: InputMaybe<Scalars["Int"]>;
  sku: Scalars["String"];
};

export type Address = {
  city: Scalars["String"];
  company?: InputMaybe<Scalars["String"]>;
  country: Scalars["String"];
  firstName: Scalars["String"];
  key?: InputMaybe<Scalars["String"]>;
  lastName: Scalars["String"];
  postalCode: Scalars["String"];
  salutation?: InputMaybe<Scalars["String"]>;
  streetName: Scalars["String"];
  streetNumber: Scalars["String"];
  type?: InputMaybe<Scalars["String"]>;
};

export type AttributeDefinition = {
  __typename?: "AttributeDefinition";
  attributeConstraint: Scalars["String"];
  label?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  type: Scalars["JSON"];
};

export type Cart = {
  __typename?: "Cart";
  billingAddress?: Maybe<CartAddress>;
  cartItems: Array<CartItem>;
  cartState?: Maybe<Scalars["String"]>;
  currencyCode?: Maybe<Scalars["String"]>;
  customerEmail?: Maybe<Scalars["String"]>;
  customerId?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  shippingAddress?: Maybe<CartAddress>;
  taxes?: Maybe<Array<CartTax>>;
  totalGrossPriceInCent: Scalars["Int"];
  totalNetPriceInCent: Scalars["Int"];
  version: Scalars["Int"];
};

export type CartAddress = {
  __typename?: "CartAddress";
  city: Scalars["String"];
  company?: Maybe<Scalars["String"]>;
  country: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  postalCode: Scalars["String"];
  salutation?: Maybe<Scalars["String"]>;
  streetName: Scalars["String"];
  streetNumber: Scalars["String"];
};

export type CartItem = {
  __typename?: "CartItem";
  id: Scalars["String"];
  images?: Maybe<Array<Image>>;
  priceInCent: Scalars["Int"];
  productId: Scalars["String"];
  productName: Scalars["String"];
  productSlug: Scalars["String"];
  quantity: Scalars["Int"];
  sku: Scalars["String"];
  tax?: Maybe<CartItemTax>;
  totalGrossPriceInCent: Scalars["Int"];
  totalNetPriceInCent: Scalars["Int"];
};

export type CartItemTax = {
  __typename?: "CartItemTax";
  amount?: Maybe<Scalars["Int"]>;
  includedInPrice?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
};

export type CartTax = {
  __typename?: "CartTax";
  amount: Scalars["Int"];
  name: Scalars["String"];
  rate: Scalars["Float"];
};

export type CartUpdateAction = {
  addLineItemAction?: InputMaybe<AddLineItemAction>;
  changeLineItemQuantityAction?: InputMaybe<ChangeLineItemQuantityAction>;
  placeOrderAction?: InputMaybe<PlaceOrderAction>;
  removeLineItemAction?: InputMaybe<RemoveLineItemAction>;
  setBillingAddressAction?: InputMaybe<SetBillingAddressAction>;
  setCustomerEmailAction?: InputMaybe<SetCustomerEmailAction>;
  setShippingAddressAction?: InputMaybe<SetShippingAddressAction>;
};

export type Category = {
  __typename?: "Category";
  description?: Maybe<Scalars["String"]>;
  hasChildren?: Maybe<Scalars["Boolean"]>;
  id: Scalars["String"];
  key?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  parentId?: Maybe<Scalars["String"]>;
  slug: Scalars["String"];
};

export type CategoryList = {
  __typename?: "CategoryList";
  result?: Maybe<Array<Maybe<Category>>>;
};

export type CategoryNode = {
  __typename?: "CategoryNode";
  category: Category;
  children?: Maybe<Array<CategoryNode>>;
};

export type CategoryReference = {
  __typename?: "CategoryReference";
  id: Scalars["String"];
};

export type CategoryTree = {
  __typename?: "CategoryTree";
  root?: Maybe<Array<CategoryNode>>;
};

export type ChangeLineItemQuantityAction = {
  lineItemId: Scalars["String"];
  quantity: Scalars["Int"];
};

export type Customer = {
  __typename?: "Customer";
  addresses: Array<CustomerAddress>;
  defaultBillingAddressKey?: Maybe<Scalars["String"]>;
  defaultShippingAddressKey?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  firstName?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  isEmailVerified?: Maybe<Scalars["Boolean"]>;
  lastName?: Maybe<Scalars["String"]>;
  salutation?: Maybe<Scalars["String"]>;
  token?: Maybe<Scalars["String"]>;
  version: Scalars["Int"];
};

export type CustomerAddress = {
  __typename?: "CustomerAddress";
  city?: Maybe<Scalars["String"]>;
  company?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  key?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  postalCode?: Maybe<Scalars["String"]>;
  salutation?: Maybe<Scalars["String"]>;
  streetName?: Maybe<Scalars["String"]>;
  streetNumber?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type CustomerUpdateAction = {
  updateBillingAddressAction?: InputMaybe<UpdateAddressAction>;
  updateShippingAddressAction?: InputMaybe<UpdateAddressAction>;
};

export type Facet = {
  __typename?: "Facet";
  dataType: Scalars["String"];
  displayOptions?: Maybe<Array<StringKeyValueTuple>>;
  label?: Maybe<Scalars["String"]>;
  missing?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  total?: Maybe<Scalars["Int"]>;
  type: Scalars["String"];
  values?: Maybe<Array<FacetValue>>;
};

export type FacetRange = {
  __typename?: "FacetRange";
  count: Scalars["Int"];
  from: Scalars["Int"];
  fromStr?: Maybe<Scalars["String"]>;
  max: Scalars["Int"];
  mean: Scalars["Int"];
  min: Scalars["Int"];
  productCount?: Maybe<Scalars["Int"]>;
  to?: Maybe<Scalars["Int"]>;
  toStr?: Maybe<Scalars["String"]>;
  total: Scalars["Int"];
};

export type FacetTerm = {
  __typename?: "FacetTerm";
  count: Scalars["Int"];
  label?: Maybe<Scalars["String"]>;
  term: Scalars["String"];
};

export type FacetValue = FacetRange | FacetTerm;

export type Filter = {
  attributes?: InputMaybe<Array<FilterAttribute>>;
  categoryId?: InputMaybe<Scalars["String"]>;
  categoryKey?: InputMaybe<Scalars["String"]>;
  categorySlug?: InputMaybe<Scalars["String"]>;
  currencyCode?: InputMaybe<Scalars["String"]>;
  text?: InputMaybe<Scalars["String"]>;
};

export type FilterAttribute = {
  name: Scalars["String"];
  values: Array<Scalars["String"]>;
};

export type Image = {
  __typename?: "Image";
  alt: Scalars["String"];
  height: Scalars["String"];
  title?: Maybe<Scalars["String"]>;
  url: Scalars["String"];
  width: Scalars["String"];
};

export type Metadata = {
  __typename?: "Metadata";
  count: Scalars["Int"];
  limit: Scalars["Int"];
  offset: Scalars["Int"];
  total: Scalars["Int"];
};

export type Mutation = {
  __typename?: "Mutation";
  batchUpdateMyCart?: Maybe<Cart>;
  batchUpdateMyCustomer?: Maybe<Customer>;
  signUpCustomer?: Maybe<Customer>;
};

export type MutationBatchUpdateMyCartArgs = {
  actions: Array<InputMaybe<CartUpdateAction>>;
  currencyCode?: InputMaybe<Scalars["String"]>;
  locale?: InputMaybe<Scalars["String"]>;
};

export type MutationBatchUpdateMyCustomerArgs = {
  actions: Array<InputMaybe<CustomerUpdateAction>>;
};

export type MutationSignUpCustomerArgs = {
  anonymousCartId?: InputMaybe<Scalars["String"]>;
  currencyCode?: InputMaybe<Scalars["String"]>;
  customerData: SignUpCustomerData;
  locale?: InputMaybe<Scalars["String"]>;
  rememberMe?: InputMaybe<Scalars["Boolean"]>;
};

export type Order = {
  __typename?: "Order";
  createdAt: Scalars["String"];
  currencyCode?: Maybe<Scalars["String"]>;
  orderItems: Array<OrderItem>;
  orderNumber: Scalars["String"];
  orderState: Scalars["String"];
  totalPriceInCent: Scalars["Int"];
};

export type OrderItem = {
  __typename?: "OrderItem";
  priceInCent: Scalars["Int"];
  productId: Scalars["String"];
  productName: Scalars["String"];
  productSlug: Scalars["String"];
  quantity: Scalars["Int"];
  sku: Scalars["String"];
  totalPriceInCent: Scalars["Int"];
};

export type OrderList = {
  __typename?: "OrderList";
  metadata: Metadata;
  orders: Array<Maybe<Order>>;
};

export type Page = {
  __typename?: "Page";
  description?: Maybe<Scalars["String"]>;
  slug: Scalars["String"];
  tags?: Maybe<Array<Maybe<Scalars["String"]>>>;
  title?: Maybe<Scalars["String"]>;
};

export type PlaceOrderAction = {
  orderState?: InputMaybe<Scalars["String"]>;
};

export type Price = {
  __typename?: "Price";
  centAmount: Scalars["Int"];
  currencyCode?: Maybe<Scalars["String"]>;
  discountedCentAmount?: Maybe<Scalars["Int"]>;
  taxType: Scalars["String"];
};

export type Product = {
  __typename?: "Product";
  attributeDefinitions?: Maybe<Array<AttributeDefinition>>;
  categoryReferences?: Maybe<Array<CategoryReference>>;
  description?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  images?: Maybe<Array<Image>>;
  name?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
  variants: Array<ProductVariant>;
};

export type ProductAttribute = {
  __typename?: "ProductAttribute";
  name: Scalars["String"];
  value: Scalars["JSON"];
};

export type ProductList = {
  __typename?: "ProductList";
  description?: Maybe<Scalars["String"]>;
  facets?: Maybe<Array<Facet>>;
  metadata: Metadata;
  name?: Maybe<Scalars["String"]>;
  products?: Maybe<Array<Product>>;
};

export type ProductListFacetsArgs = {
  selected?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ProductVariant = {
  __typename?: "ProductVariant";
  attributesRaw: Array<ProductAttribute>;
  id: Scalars["String"];
  isMaster: Scalars["Boolean"];
  isMatching: Scalars["Boolean"];
  prices: Array<Price>;
  sku: Scalars["String"];
};

export type Query = {
  __typename?: "Query";
  _service: _Service;
  /** returns a category for a given category-ID */
  categoryById?: Maybe<Category>;
  /** returns a category for a given key */
  categoryByKey?: Maybe<Category>;
  /** returns a category for a given slug */
  categoryBySlug?: Maybe<Category>;
  /** returns a list of categories (max. 500) */
  categoryList?: Maybe<CategoryList>;
  categoryTree?: Maybe<CategoryTree>;
  /** returns a list of categories for a given parent category-ID (max. 500) */
  childCategoriesByParentId?: Maybe<CategoryList>;
  /** returns a list of categories for a given parent category-slug (max. 500) */
  childCategoriesByParentSlug?: Maybe<CategoryList>;
  content?: Maybe<Scalars["JSON"]>;
  myCart?: Maybe<Cart>;
  myCustomer?: Maybe<Customer>;
  myOrders?: Maybe<OrderList>;
  pageContent?: Maybe<Scalars["JSON"]>;
  pageMetadata?: Maybe<Array<Maybe<Page>>>;
  /** returns a product for a given product-ID */
  productById?: Maybe<Product>;
  /** returns a product for a given slug */
  productBySlug?: Maybe<Product>;
  /** returns a pageable list of products */
  productList?: Maybe<ProductList>;
  signInCustomer?: Maybe<Customer>;
  signOutCustomer?: Maybe<Scalars["Boolean"]>;
};

export type QueryCategoryByIdArgs = {
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
};

export type QueryCategoryByKeyArgs = {
  key: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
};

export type QueryCategoryBySlugArgs = {
  locale?: InputMaybe<Scalars["String"]>;
  slug: Scalars["String"];
};

export type QueryCategoryListArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

export type QueryCategoryTreeArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

export type QueryChildCategoriesByParentIdArgs = {
  locale?: InputMaybe<Scalars["String"]>;
  parentId?: InputMaybe<Scalars["String"]>;
};

export type QueryChildCategoriesByParentSlugArgs = {
  locale?: InputMaybe<Scalars["String"]>;
  parentSlug?: InputMaybe<Scalars["String"]>;
};

export type QueryContentArgs = {
  locale?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  page?: InputMaybe<Scalars["String"]>;
  position?: InputMaybe<Scalars["String"]>;
};

export type QueryMyCartArgs = {
  currencyCode?: InputMaybe<Scalars["String"]>;
  locale?: InputMaybe<Scalars["String"]>;
};

export type QueryMyOrdersArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

export type QueryPageContentArgs = {
  locale?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  position?: InputMaybe<Scalars["String"]>;
  slug?: InputMaybe<Scalars["String"]>;
};

export type QueryPageMetadataArgs = {
  locale?: InputMaybe<Scalars["String"]>;
  slugs?: InputMaybe<Scalars["String"]>;
};

export type QueryProductByIdArgs = {
  currencyCode?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
};

export type QueryProductBySlugArgs = {
  currencyCode?: InputMaybe<Scalars["String"]>;
  locale?: InputMaybe<Scalars["String"]>;
  slug: Scalars["String"];
};

export type QueryProductListArgs = {
  currencyCode?: InputMaybe<Scalars["String"]>;
  filter: Filter;
  locale?: InputMaybe<Scalars["String"]>;
  page?: InputMaybe<Scalars["Int"]>;
  perPage?: InputMaybe<Scalars["Int"]>;
};

export type QuerySignInCustomerArgs = {
  anonymousCartId?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  password: Scalars["String"];
  rememberMe?: InputMaybe<Scalars["Boolean"]>;
};

export type RemoveLineItemAction = {
  lineItemId: Scalars["String"];
};

export type SetBillingAddressAction = {
  billingAddress: Address;
};

export type SetCustomerEmailAction = {
  email: Scalars["String"];
};

export type SetShippingAddressAction = {
  shippingAddress: Address;
};

export type SignUpCustomerData = {
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  password: Scalars["String"];
  salutation?: InputMaybe<Scalars["String"]>;
};

export type StringKeyValueTuple = {
  __typename?: "StringKeyValueTuple";
  key: Scalars["String"];
  value: Scalars["String"];
};

export type UpdateAddressAction = {
  address: Address;
};

export type _Service = {
  __typename?: "_Service";
  sdl?: Maybe<Scalars["String"]>;
};

export type CartFragment = {
  __typename?: "Cart";
  id: string;
  cartState?: string | null;
  customerId?: string | null;
  customerEmail?: string | null;
  version: number;
  totalNetPriceInCent: number;
  totalGrossPriceInCent: number;
  currencyCode?: string | null;
  taxes?: Array<{
    __typename?: "CartTax";
    amount: number;
    name: string;
    rate: number;
  }> | null;
  billingAddress?: {
    __typename?: "CartAddress";
    city: string;
    company?: string | null;
    country: string;
    firstName: string;
    lastName: string;
    postalCode: string;
    streetName: string;
    streetNumber: string;
  } | null;
  shippingAddress?: {
    __typename?: "CartAddress";
    city: string;
    company?: string | null;
    country: string;
    firstName: string;
    lastName: string;
    postalCode: string;
    streetName: string;
    streetNumber: string;
  } | null;
  cartItems: Array<{
    __typename?: "CartItem";
    id: string;
    quantity: number;
    productId: string;
    productName: string;
    productSlug: string;
    sku: string;
    priceInCent: number;
    totalNetPriceInCent: number;
    totalGrossPriceInCent: number;
    images?: Array<{
      __typename?: "Image";
      alt: string;
      height: string;
      title?: string | null;
      url: string;
      width: string;
    }> | null;
  }>;
};

export type CartAddressFragment = {
  __typename?: "CartAddress";
  city: string;
  company?: string | null;
  country: string;
  firstName: string;
  lastName: string;
  postalCode: string;
  streetName: string;
  streetNumber: string;
};

export type CartItemFragment = {
  __typename?: "CartItem";
  id: string;
  quantity: number;
  productId: string;
  productName: string;
  productSlug: string;
  sku: string;
  priceInCent: number;
  totalNetPriceInCent: number;
  totalGrossPriceInCent: number;
  images?: Array<{
    __typename?: "Image";
    alt: string;
    height: string;
    title?: string | null;
    url: string;
    width: string;
  }> | null;
};

export type GetCartQueryVariables = Exact<{
  currencyCode?: InputMaybe<Scalars["String"]>;
  locale?: InputMaybe<Scalars["String"]>;
}>;

export type GetCartQuery = {
  __typename?: "Query";
  myCart?: {
    __typename?: "Cart";
    id: string;
    cartState?: string | null;
    customerId?: string | null;
    customerEmail?: string | null;
    version: number;
    totalNetPriceInCent: number;
    totalGrossPriceInCent: number;
    currencyCode?: string | null;
    taxes?: Array<{
      __typename?: "CartTax";
      amount: number;
      name: string;
      rate: number;
    }> | null;
    billingAddress?: {
      __typename?: "CartAddress";
      city: string;
      company?: string | null;
      country: string;
      firstName: string;
      lastName: string;
      postalCode: string;
      streetName: string;
      streetNumber: string;
    } | null;
    shippingAddress?: {
      __typename?: "CartAddress";
      city: string;
      company?: string | null;
      country: string;
      firstName: string;
      lastName: string;
      postalCode: string;
      streetName: string;
      streetNumber: string;
    } | null;
    cartItems: Array<{
      __typename?: "CartItem";
      id: string;
      quantity: number;
      productId: string;
      productName: string;
      productSlug: string;
      sku: string;
      priceInCent: number;
      totalNetPriceInCent: number;
      totalGrossPriceInCent: number;
      images?: Array<{
        __typename?: "Image";
        alt: string;
        height: string;
        title?: string | null;
        url: string;
        width: string;
      }> | null;
    }>;
  } | null;
};

export type UpdateCartMutationVariables = Exact<{
  actions: Array<InputMaybe<CartUpdateAction>> | InputMaybe<CartUpdateAction>;
  locale?: InputMaybe<Scalars["String"]>;
  currencyCode?: InputMaybe<Scalars["String"]>;
}>;

export type UpdateCartMutation = {
  __typename?: "Mutation";
  batchUpdateMyCart?: {
    __typename?: "Cart";
    id: string;
    cartState?: string | null;
    customerId?: string | null;
    customerEmail?: string | null;
    version: number;
    totalNetPriceInCent: number;
    totalGrossPriceInCent: number;
    currencyCode?: string | null;
    taxes?: Array<{
      __typename?: "CartTax";
      amount: number;
      name: string;
      rate: number;
    }> | null;
    billingAddress?: {
      __typename?: "CartAddress";
      city: string;
      company?: string | null;
      country: string;
      firstName: string;
      lastName: string;
      postalCode: string;
      streetName: string;
      streetNumber: string;
    } | null;
    shippingAddress?: {
      __typename?: "CartAddress";
      city: string;
      company?: string | null;
      country: string;
      firstName: string;
      lastName: string;
      postalCode: string;
      streetName: string;
      streetNumber: string;
    } | null;
    cartItems: Array<{
      __typename?: "CartItem";
      id: string;
      quantity: number;
      productId: string;
      productName: string;
      productSlug: string;
      sku: string;
      priceInCent: number;
      totalNetPriceInCent: number;
      totalGrossPriceInCent: number;
      images?: Array<{
        __typename?: "Image";
        alt: string;
        height: string;
        title?: string | null;
        url: string;
        width: string;
      }> | null;
    }>;
  } | null;
};

export type CategoryFragment = {
  __typename?: "Category";
  id: string;
  key?: string | null;
  name: string;
  slug: string;
  description?: string | null;
};

export type CategoryTreeItemFragment = {
  __typename?: "CategoryNode";
  category: { __typename?: "Category"; id: string; name: string; slug: string };
};

export type GetCategoryQueryVariables = Exact<{
  categorySlug: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
}>;

export type GetCategoryQuery = {
  __typename?: "Query";
  categoryBySlug?: {
    __typename?: "Category";
    id: string;
    key?: string | null;
    name: string;
    slug: string;
    description?: string | null;
  } | null;
};

export type GetCategoryTreeQueryVariables = Exact<{
  locale?: InputMaybe<Scalars["String"]>;
}>;

export type GetCategoryTreeQuery = {
  __typename?: "Query";
  categoryTree?: {
    __typename?: "CategoryTree";
    root?: Array<{
      __typename?: "CategoryNode";
      children?: Array<{
        __typename?: "CategoryNode";
        children?: Array<{
          __typename?: "CategoryNode";
          children?: Array<{
            __typename?: "CategoryNode";
            category: {
              __typename?: "Category";
              id: string;
              name: string;
              slug: string;
            };
          }> | null;
          category: {
            __typename?: "Category";
            id: string;
            name: string;
            slug: string;
          };
        }> | null;
        category: {
          __typename?: "Category";
          id: string;
          name: string;
          slug: string;
        };
      }> | null;
      category: {
        __typename?: "Category";
        id: string;
        name: string;
        slug: string;
      };
    }> | null;
  } | null;
};

export type FacetFragment = {
  __typename?: "Facet";
  name: string;
  type: string;
  dataType: string;
  label?: string | null;
  values?: Array<
    | {
        __typename?: "FacetRange";
        from: number;
        fromStr?: string | null;
        to?: number | null;
        toStr?: string | null;
        count: number;
        productCount?: number | null;
        total: number;
        min: number;
        max: number;
        mean: number;
      }
    | {
        __typename?: "FacetTerm";
        term: string;
        count: number;
        label?: string | null;
      }
  > | null;
  displayOptions?: Array<{
    __typename?: "StringKeyValueTuple";
    key: string;
    value: string;
  }> | null;
};

export type GetProductListQueryVariables = Exact<{
  categorySlug: Scalars["String"];
  facets?: InputMaybe<Array<FilterAttribute> | FilterAttribute>;
  search?: InputMaybe<Scalars["String"]>;
  locale: Scalars["String"];
  page?: InputMaybe<Scalars["Int"]>;
  perPage?: InputMaybe<Scalars["Int"]>;
  currencyCode?: InputMaybe<Scalars["String"]>;
}>;

export type GetProductListQuery = {
  __typename?: "Query";
  productList?: {
    __typename?: "ProductList";
    products?: Array<{
      __typename?: "Product";
      id: string;
      slug?: string | null;
      name?: string | null;
      description?: string | null;
      attributeDefinitions?: Array<{
        __typename?: "AttributeDefinition";
        attributeConstraint: string;
        label?: string | null;
        name: string;
        type: any;
      }> | null;
      variants: Array<{
        __typename?: "ProductVariant";
        id: string;
        sku: string;
        isMaster: boolean;
        isMatching: boolean;
        attributesRaw: Array<{
          __typename?: "ProductAttribute";
          name: string;
          value: any;
        }>;
        prices: Array<{
          __typename?: "Price";
          centAmount: number;
          discountedCentAmount?: number | null;
          taxType: string;
        }>;
      }>;
      images?: Array<{
        __typename?: "Image";
        alt: string;
        height: string;
        title?: string | null;
        url: string;
        width: string;
      }> | null;
    }> | null;
    facets?: Array<{
      __typename?: "Facet";
      name: string;
      type: string;
      dataType: string;
      label?: string | null;
      values?: Array<
        | {
            __typename?: "FacetRange";
            from: number;
            fromStr?: string | null;
            to?: number | null;
            toStr?: string | null;
            count: number;
            productCount?: number | null;
            total: number;
            min: number;
            max: number;
            mean: number;
          }
        | {
            __typename?: "FacetTerm";
            term: string;
            count: number;
            label?: string | null;
          }
      > | null;
      displayOptions?: Array<{
        __typename?: "StringKeyValueTuple";
        key: string;
        value: string;
      }> | null;
    }> | null;
    metadata: {
      __typename?: "Metadata";
      limit: number;
      count: number;
      offset: number;
      total: number;
    };
  } | null;
};

export type MetadataFragment = {
  __typename?: "Metadata";
  limit: number;
  count: number;
  offset: number;
  total: number;
};

export type ProductFragment = {
  __typename?: "Product";
  id: string;
  slug?: string | null;
  name?: string | null;
  description?: string | null;
  attributeDefinitions?: Array<{
    __typename?: "AttributeDefinition";
    attributeConstraint: string;
    label?: string | null;
    name: string;
    type: any;
  }> | null;
  variants: Array<{
    __typename?: "ProductVariant";
    id: string;
    sku: string;
    isMaster: boolean;
    isMatching: boolean;
    attributesRaw: Array<{
      __typename?: "ProductAttribute";
      name: string;
      value: any;
    }>;
    prices: Array<{
      __typename?: "Price";
      centAmount: number;
      discountedCentAmount?: number | null;
      taxType: string;
    }>;
  }>;
  images?: Array<{
    __typename?: "Image";
    alt: string;
    height: string;
    title?: string | null;
    url: string;
    width: string;
  }> | null;
};

export type ProductListFragment = {
  __typename?: "ProductList";
  products?: Array<{
    __typename?: "Product";
    id: string;
    slug?: string | null;
    name?: string | null;
    description?: string | null;
    attributeDefinitions?: Array<{
      __typename?: "AttributeDefinition";
      attributeConstraint: string;
      label?: string | null;
      name: string;
      type: any;
    }> | null;
    variants: Array<{
      __typename?: "ProductVariant";
      id: string;
      sku: string;
      isMaster: boolean;
      isMatching: boolean;
      attributesRaw: Array<{
        __typename?: "ProductAttribute";
        name: string;
        value: any;
      }>;
      prices: Array<{
        __typename?: "Price";
        centAmount: number;
        discountedCentAmount?: number | null;
        taxType: string;
      }>;
    }>;
    images?: Array<{
      __typename?: "Image";
      alt: string;
      height: string;
      title?: string | null;
      url: string;
      width: string;
    }> | null;
  }> | null;
  facets?: Array<{
    __typename?: "Facet";
    name: string;
    type: string;
    dataType: string;
    label?: string | null;
    values?: Array<
      | {
          __typename?: "FacetRange";
          from: number;
          fromStr?: string | null;
          to?: number | null;
          toStr?: string | null;
          count: number;
          productCount?: number | null;
          total: number;
          min: number;
          max: number;
          mean: number;
        }
      | {
          __typename?: "FacetTerm";
          term: string;
          count: number;
          label?: string | null;
        }
    > | null;
    displayOptions?: Array<{
      __typename?: "StringKeyValueTuple";
      key: string;
      value: string;
    }> | null;
  }> | null;
  metadata: {
    __typename?: "Metadata";
    limit: number;
    count: number;
    offset: number;
    total: number;
  };
};

export type AttributeDefinitionFragment = {
  __typename?: "AttributeDefinition";
  attributeConstraint: string;
  label?: string | null;
  name: string;
  type: any;
};

export type GetProductQueryVariables = Exact<{
  productSlug: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  currencyCode?: InputMaybe<Scalars["String"]>;
  includeCategoryReferences?: InputMaybe<Scalars["Boolean"]>;
}>;

export type GetProductQuery = {
  __typename?: "Query";
  productBySlug?: {
    __typename?: "Product";
    id: string;
    slug?: string | null;
    name?: string | null;
    description?: string | null;
    attributeDefinitions?: Array<{
      __typename?: "AttributeDefinition";
      attributeConstraint: string;
      label?: string | null;
      name: string;
      type: any;
    }> | null;
    variants: Array<{
      __typename?: "ProductVariant";
      id: string;
      sku: string;
      isMaster: boolean;
      isMatching: boolean;
      attributesRaw: Array<{
        __typename?: "ProductAttribute";
        name: string;
        value: any;
      }>;
      prices: Array<{
        __typename?: "Price";
        centAmount: number;
        discountedCentAmount?: number | null;
        taxType: string;
      }>;
    }>;
    images?: Array<{
      __typename?: "Image";
      alt: string;
      height: string;
      title?: string | null;
      url: string;
      width: string;
    }> | null;
    categoryReferences?: Array<{
      __typename?: "CategoryReference";
      id: string;
    }> | null;
  } | null;
};

export type ProductVariantFragment = {
  __typename?: "ProductVariant";
  id: string;
  sku: string;
  isMaster: boolean;
  isMatching: boolean;
  attributesRaw: Array<{
    __typename?: "ProductAttribute";
    name: string;
    value: any;
  }>;
  prices: Array<{
    __typename?: "Price";
    centAmount: number;
    discountedCentAmount?: number | null;
    taxType: string;
  }>;
};

export type GetPageQueryVariables = Exact<{
  slug?: InputMaybe<Scalars["String"]>;
  position?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  locale?: InputMaybe<Scalars["String"]>;
}>;

export type GetPageQuery = { __typename?: "Query"; pageContent?: any | null };

export type ImageFragment = {
  __typename?: "Image";
  alt: string;
  height: string;
  title?: string | null;
  url: string;
  width: string;
};

export type CustomerFragment = {
  __typename?: "Customer";
  id: string;
  version: number;
  email: string;
  isEmailVerified?: boolean | null;
  token?: string | null;
  defaultBillingAddressKey?: string | null;
  defaultShippingAddressKey?: string | null;
  addresses: Array<{
    __typename?: "CustomerAddress";
    key?: string | null;
    type?: string | null;
    streetName?: string | null;
    salutation?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    streetNumber?: string | null;
    postalCode?: string | null;
    city?: string | null;
    country?: string | null;
    company?: string | null;
  }>;
};

export type CustomerAddressFragment = {
  __typename?: "CustomerAddress";
  key?: string | null;
  type?: string | null;
  streetName?: string | null;
  salutation?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  streetNumber?: string | null;
  postalCode?: string | null;
  city?: string | null;
  country?: string | null;
  company?: string | null;
};

export type GetCustomerQueryVariables = Exact<{ [key: string]: never }>;

export type GetCustomerQuery = {
  __typename?: "Query";
  myCustomer?: {
    __typename?: "Customer";
    id: string;
    version: number;
    email: string;
    isEmailVerified?: boolean | null;
    token?: string | null;
    defaultBillingAddressKey?: string | null;
    defaultShippingAddressKey?: string | null;
    addresses: Array<{
      __typename?: "CustomerAddress";
      key?: string | null;
      type?: string | null;
      streetName?: string | null;
      salutation?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      streetNumber?: string | null;
      postalCode?: string | null;
      city?: string | null;
      country?: string | null;
      company?: string | null;
    }>;
  } | null;
};

export type SignInCustomerQueryVariables = Exact<{
  email: Scalars["String"];
  password: Scalars["String"];
  anonymousCartId?: InputMaybe<Scalars["String"]>;
  rememberMe?: InputMaybe<Scalars["Boolean"]>;
}>;

export type SignInCustomerQuery = {
  __typename?: "Query";
  signInCustomer?: {
    __typename?: "Customer";
    id: string;
    version: number;
    email: string;
    isEmailVerified?: boolean | null;
    token?: string | null;
    defaultBillingAddressKey?: string | null;
    defaultShippingAddressKey?: string | null;
    addresses: Array<{
      __typename?: "CustomerAddress";
      key?: string | null;
      type?: string | null;
      streetName?: string | null;
      salutation?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      streetNumber?: string | null;
      postalCode?: string | null;
      city?: string | null;
      country?: string | null;
      company?: string | null;
    }>;
  } | null;
};

export type SignOutCustomerQueryVariables = Exact<{ [key: string]: never }>;

export type SignOutCustomerQuery = {
  __typename?: "Query";
  signOutCustomer?: boolean | null;
};

export type SignUpCustomerMutationVariables = Exact<{
  customerData: SignUpCustomerData;
  anonymousCartId?: InputMaybe<Scalars["String"]>;
  rememberMe?: InputMaybe<Scalars["Boolean"]>;
  locale?: InputMaybe<Scalars["String"]>;
  currencyCode?: InputMaybe<Scalars["String"]>;
}>;

export type SignUpCustomerMutation = {
  __typename?: "Mutation";
  signUpCustomer?: {
    __typename?: "Customer";
    id: string;
    version: number;
    email: string;
    isEmailVerified?: boolean | null;
    token?: string | null;
    defaultBillingAddressKey?: string | null;
    defaultShippingAddressKey?: string | null;
    addresses: Array<{
      __typename?: "CustomerAddress";
      key?: string | null;
      type?: string | null;
      streetName?: string | null;
      salutation?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      streetNumber?: string | null;
      postalCode?: string | null;
      city?: string | null;
      country?: string | null;
      company?: string | null;
    }>;
  } | null;
};

export type UpdateCustomerMutationVariables = Exact<{
  actions:
    | Array<InputMaybe<CustomerUpdateAction>>
    | InputMaybe<CustomerUpdateAction>;
}>;

export type UpdateCustomerMutation = {
  __typename?: "Mutation";
  batchUpdateMyCustomer?: {
    __typename?: "Customer";
    id: string;
    version: number;
    email: string;
    isEmailVerified?: boolean | null;
    token?: string | null;
    defaultBillingAddressKey?: string | null;
    defaultShippingAddressKey?: string | null;
    addresses: Array<{
      __typename?: "CustomerAddress";
      key?: string | null;
      type?: string | null;
      streetName?: string | null;
      salutation?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      streetNumber?: string | null;
      postalCode?: string | null;
      city?: string | null;
      country?: string | null;
      company?: string | null;
    }>;
  } | null;
};

export type GetOrderListQueryVariables = Exact<{
  locale?: InputMaybe<Scalars["String"]>;
}>;

export type GetOrderListQuery = {
  __typename?: "Query";
  myOrders?: {
    __typename?: "OrderList";
    orders: Array<{
      __typename?: "Order";
      orderNumber: string;
      createdAt: string;
      totalPriceInCent: number;
      currencyCode?: string | null;
      orderState: string;
      orderItems: Array<{
        __typename?: "OrderItem";
        productId: string;
        productName: string;
        productSlug: string;
        sku: string;
        totalPriceInCent: number;
        priceInCent: number;
        quantity: number;
      }>;
    } | null>;
    metadata: {
      __typename?: "Metadata";
      limit: number;
      count: number;
      offset: number;
      total: number;
    };
  } | null;
};

export type OrderFragment = {
  __typename?: "Order";
  orderNumber: string;
  createdAt: string;
  totalPriceInCent: number;
  currencyCode?: string | null;
  orderState: string;
  orderItems: Array<{
    __typename?: "OrderItem";
    productId: string;
    productName: string;
    productSlug: string;
    sku: string;
    totalPriceInCent: number;
    priceInCent: number;
    quantity: number;
  }>;
};

export type OrderItemFragment = {
  __typename?: "OrderItem";
  productId: string;
  productName: string;
  productSlug: string;
  sku: string;
  totalPriceInCent: number;
  priceInCent: number;
  quantity: number;
};

export type OrderListFragment = {
  __typename?: "OrderList";
  orders: Array<{
    __typename?: "Order";
    orderNumber: string;
    createdAt: string;
    totalPriceInCent: number;
    currencyCode?: string | null;
    orderState: string;
    orderItems: Array<{
      __typename?: "OrderItem";
      productId: string;
      productName: string;
      productSlug: string;
      sku: string;
      totalPriceInCent: number;
      priceInCent: number;
      quantity: number;
    }>;
  } | null>;
  metadata: {
    __typename?: "Metadata";
    limit: number;
    count: number;
    offset: number;
    total: number;
  };
};

export type GetCategorySitemapQueryVariables = Exact<{
  locale?: InputMaybe<Scalars["String"]>;
}>;

export type GetCategorySitemapQuery = {
  __typename?: "Query";
  categoryList?: {
    __typename?: "CategoryList";
    result?: Array<{ __typename?: "Category"; slug: string } | null> | null;
  } | null;
};

export type GetCmsSitemapQueryVariables = Exact<{
  slugs?: InputMaybe<Scalars["String"]>;
  locale?: InputMaybe<Scalars["String"]>;
}>;

export type GetCmsSitemapQuery = {
  __typename?: "Query";
  pageMetadata?: Array<{ __typename?: "Page"; slug: string } | null> | null;
};

export type GetProductSitemapQueryVariables = Exact<{
  locale?: InputMaybe<Scalars["String"]>;
  page?: InputMaybe<Scalars["Int"]>;
  perPage?: InputMaybe<Scalars["Int"]>;
  currencyCode?: InputMaybe<Scalars["String"]>;
}>;

export type GetProductSitemapQuery = {
  __typename?: "Query";
  productList?: {
    __typename?: "ProductList";
    products?: Array<{ __typename?: "Product"; slug?: string | null }> | null;
    metadata: {
      __typename?: "Metadata";
      count: number;
      offset: number;
      total: number;
    };
  } | null;
};

export const CartAddressFragmentDoc = gql`
  fragment CartAddress on CartAddress {
    city
    company
    country
    firstName
    lastName
    postalCode
    streetName
    streetNumber
  }
`;
export const ImageFragmentDoc = gql`
  fragment Image on Image {
    alt
    height
    title
    url
    width
  }
`;
export const CartItemFragmentDoc = gql`
  fragment CartItem on CartItem {
    id
    quantity
    productId
    productName
    productSlug
    sku
    priceInCent
    totalNetPriceInCent
    totalGrossPriceInCent
    images {
      ...Image
    }
  }
  ${ImageFragmentDoc}
`;
export const CartFragmentDoc = gql`
  fragment Cart on Cart {
    id
    cartState
    customerId
    customerEmail
    version
    totalNetPriceInCent
    totalGrossPriceInCent
    currencyCode
    taxes {
      amount
      name
      rate
    }
    billingAddress {
      ...CartAddress
    }
    shippingAddress {
      ...CartAddress
    }
    cartItems {
      ...CartItem
    }
  }
  ${CartAddressFragmentDoc}
  ${CartItemFragmentDoc}
`;
export const CategoryFragmentDoc = gql`
  fragment Category on Category {
    id
    key
    name
    slug
    description
  }
`;
export const CategoryTreeItemFragmentDoc = gql`
  fragment CategoryTreeItem on CategoryNode {
    category {
      id
      name
      slug
    }
  }
`;
export const AttributeDefinitionFragmentDoc = gql`
  fragment AttributeDefinition on AttributeDefinition {
    attributeConstraint
    label
    name
    type
  }
`;
export const ProductVariantFragmentDoc = gql`
  fragment ProductVariant on ProductVariant {
    attributesRaw {
      ... on ProductAttribute {
        name
        value
      }
    }
    id
    sku
    isMaster
    isMatching
    prices {
      ... on Price {
        centAmount
        discountedCentAmount
        taxType
      }
    }
  }
`;
export const ProductFragmentDoc = gql`
  fragment Product on Product {
    id
    slug
    name
    description
    attributeDefinitions {
      ...AttributeDefinition
    }
    variants {
      ...ProductVariant
    }
    images {
      ...Image
    }
  }
  ${AttributeDefinitionFragmentDoc}
  ${ProductVariantFragmentDoc}
  ${ImageFragmentDoc}
`;
export const FacetFragmentDoc = gql`
  fragment Facet on Facet {
    name
    type
    dataType
    label
    values {
      ... on FacetRange {
        from
        fromStr
        to
        toStr
        count
        productCount
        total
        min
        max
        mean
      }
      ... on FacetTerm {
        term
        count
        label
      }
    }
    displayOptions {
      key
      value
    }
  }
`;
export const MetadataFragmentDoc = gql`
  fragment Metadata on Metadata {
    limit
    count
    offset
    total
  }
`;
export const ProductListFragmentDoc = gql`
  fragment ProductList on ProductList {
    products {
      ...Product
    }
    facets {
      ...Facet
    }
    metadata {
      ...Metadata
    }
  }
  ${ProductFragmentDoc}
  ${FacetFragmentDoc}
  ${MetadataFragmentDoc}
`;
export const CustomerAddressFragmentDoc = gql`
  fragment CustomerAddress on CustomerAddress {
    key
    type
    streetName
    salutation
    firstName
    lastName
    streetName
    streetNumber
    postalCode
    city
    country
    company
  }
`;
export const CustomerFragmentDoc = gql`
  fragment Customer on Customer {
    id
    version
    email
    isEmailVerified
    token
    addresses {
      ...CustomerAddress
    }
    defaultBillingAddressKey
    defaultShippingAddressKey
  }
  ${CustomerAddressFragmentDoc}
`;
export const OrderItemFragmentDoc = gql`
  fragment OrderItem on OrderItem {
    productId
    productName
    productSlug
    sku
    totalPriceInCent
    priceInCent
    quantity
  }
`;
export const OrderFragmentDoc = gql`
  fragment Order on Order {
    orderNumber
    createdAt
    orderItems {
      ...OrderItem
    }
    totalPriceInCent
    currencyCode
    orderState
  }
  ${OrderItemFragmentDoc}
`;
export const OrderListFragmentDoc = gql`
  fragment OrderList on OrderList {
    orders {
      ...Order
    }
    metadata {
      ...Metadata
    }
  }
  ${OrderFragmentDoc}
  ${MetadataFragmentDoc}
`;
export const GetCartDocument = gql`
  query GetCart($currencyCode: String, $locale: String) {
    myCart(currencyCode: $currencyCode, locale: $locale) {
      ...Cart
    }
  }
  ${CartFragmentDoc}
`;

/**
 * __useGetCartQuery__
 *
 * To run a query within a React component, call `useGetCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCartQuery({
 *   variables: {
 *      currencyCode: // value for 'currencyCode'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetCartQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCartQuery, GetCartQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCartQuery, GetCartQueryVariables>(
    GetCartDocument,
    options
  );
}
export function useGetCartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCartQuery, GetCartQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCartQuery, GetCartQueryVariables>(
    GetCartDocument,
    options
  );
}
export type GetCartQueryHookResult = ReturnType<typeof useGetCartQuery>;
export type GetCartLazyQueryHookResult = ReturnType<typeof useGetCartLazyQuery>;
export type GetCartQueryResult = Apollo.QueryResult<
  GetCartQuery,
  GetCartQueryVariables
>;
export const UpdateCartDocument = gql`
  mutation UpdateCart(
    $actions: [CartUpdateAction]!
    $locale: String
    $currencyCode: String
  ) {
    batchUpdateMyCart(
      actions: $actions
      locale: $locale
      currencyCode: $currencyCode
    ) {
      ...Cart
    }
  }
  ${CartFragmentDoc}
`;
export type UpdateCartMutationFn = Apollo.MutationFunction<
  UpdateCartMutation,
  UpdateCartMutationVariables
>;

/**
 * __useUpdateCartMutation__
 *
 * To run a mutation, you first call `useUpdateCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCartMutation, { data, loading, error }] = useUpdateCartMutation({
 *   variables: {
 *      actions: // value for 'actions'
 *      locale: // value for 'locale'
 *      currencyCode: // value for 'currencyCode'
 *   },
 * });
 */
export function useUpdateCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCartMutation,
    UpdateCartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCartMutation, UpdateCartMutationVariables>(
    UpdateCartDocument,
    options
  );
}
export type UpdateCartMutationHookResult = ReturnType<
  typeof useUpdateCartMutation
>;
export type UpdateCartMutationResult =
  Apollo.MutationResult<UpdateCartMutation>;
export type UpdateCartMutationOptions = Apollo.BaseMutationOptions<
  UpdateCartMutation,
  UpdateCartMutationVariables
>;
export const GetCategoryDocument = gql`
  query GetCategory($categorySlug: String!, $locale: String) {
    categoryBySlug(slug: $categorySlug, locale: $locale) {
      ...Category
    }
  }
  ${CategoryFragmentDoc}
`;

/**
 * __useGetCategoryQuery__
 *
 * To run a query within a React component, call `useGetCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryQuery({
 *   variables: {
 *      categorySlug: // value for 'categorySlug'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetCategoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCategoryQuery,
    GetCategoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCategoryQuery, GetCategoryQueryVariables>(
    GetCategoryDocument,
    options
  );
}
export function useGetCategoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCategoryQuery,
    GetCategoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCategoryQuery, GetCategoryQueryVariables>(
    GetCategoryDocument,
    options
  );
}
export type GetCategoryQueryHookResult = ReturnType<typeof useGetCategoryQuery>;
export type GetCategoryLazyQueryHookResult = ReturnType<
  typeof useGetCategoryLazyQuery
>;
export type GetCategoryQueryResult = Apollo.QueryResult<
  GetCategoryQuery,
  GetCategoryQueryVariables
>;
export const GetCategoryTreeDocument = gql`
  query GetCategoryTree($locale: String) {
    categoryTree(locale: $locale) {
      root {
        ...CategoryTreeItem
        children {
          ...CategoryTreeItem
          children {
            ...CategoryTreeItem
            children {
              ...CategoryTreeItem
            }
          }
        }
      }
    }
  }
  ${CategoryTreeItemFragmentDoc}
`;

/**
 * __useGetCategoryTreeQuery__
 *
 * To run a query within a React component, call `useGetCategoryTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryTreeQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetCategoryTreeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCategoryTreeQuery,
    GetCategoryTreeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCategoryTreeQuery, GetCategoryTreeQueryVariables>(
    GetCategoryTreeDocument,
    options
  );
}
export function useGetCategoryTreeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCategoryTreeQuery,
    GetCategoryTreeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCategoryTreeQuery,
    GetCategoryTreeQueryVariables
  >(GetCategoryTreeDocument, options);
}
export type GetCategoryTreeQueryHookResult = ReturnType<
  typeof useGetCategoryTreeQuery
>;
export type GetCategoryTreeLazyQueryHookResult = ReturnType<
  typeof useGetCategoryTreeLazyQuery
>;
export type GetCategoryTreeQueryResult = Apollo.QueryResult<
  GetCategoryTreeQuery,
  GetCategoryTreeQueryVariables
>;
export const GetProductListDocument = gql`
  query GetProductList(
    $categorySlug: String!
    $facets: [FilterAttribute!]
    $search: String
    $locale: String!
    $page: Int
    $perPage: Int
    $currencyCode: String
  ) {
    productList(
      filter: {
        categorySlug: $categorySlug
        attributes: $facets
        text: $search
      }
      locale: $locale
      page: $page
      perPage: $perPage
      currencyCode: $currencyCode
    ) {
      ...ProductList
    }
  }
  ${ProductListFragmentDoc}
`;

/**
 * __useGetProductListQuery__
 *
 * To run a query within a React component, call `useGetProductListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductListQuery({
 *   variables: {
 *      categorySlug: // value for 'categorySlug'
 *      facets: // value for 'facets'
 *      search: // value for 'search'
 *      locale: // value for 'locale'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      currencyCode: // value for 'currencyCode'
 *   },
 * });
 */
export function useGetProductListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProductListQuery,
    GetProductListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductListQuery, GetProductListQueryVariables>(
    GetProductListDocument,
    options
  );
}
export function useGetProductListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductListQuery,
    GetProductListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductListQuery, GetProductListQueryVariables>(
    GetProductListDocument,
    options
  );
}
export type GetProductListQueryHookResult = ReturnType<
  typeof useGetProductListQuery
>;
export type GetProductListLazyQueryHookResult = ReturnType<
  typeof useGetProductListLazyQuery
>;
export type GetProductListQueryResult = Apollo.QueryResult<
  GetProductListQuery,
  GetProductListQueryVariables
>;
export const GetProductDocument = gql`
  query GetProduct(
    $productSlug: String!
    $locale: String
    $currencyCode: String
    $includeCategoryReferences: Boolean = false
  ) {
    productBySlug(
      slug: $productSlug
      locale: $locale
      currencyCode: $currencyCode
    ) {
      id
      slug
      name
      description
      attributeDefinitions {
        ...AttributeDefinition
      }
      variants {
        ...ProductVariant
      }
      images {
        ...Image
      }
      categoryReferences @include(if: $includeCategoryReferences) {
        id
      }
    }
  }
  ${AttributeDefinitionFragmentDoc}
  ${ProductVariantFragmentDoc}
  ${ImageFragmentDoc}
`;

/**
 * __useGetProductQuery__
 *
 * To run a query within a React component, call `useGetProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductQuery({
 *   variables: {
 *      productSlug: // value for 'productSlug'
 *      locale: // value for 'locale'
 *      currencyCode: // value for 'currencyCode'
 *      includeCategoryReferences: // value for 'includeCategoryReferences'
 *   },
 * });
 */
export function useGetProductQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProductQuery,
    GetProductQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductQuery, GetProductQueryVariables>(
    GetProductDocument,
    options
  );
}
export function useGetProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductQuery,
    GetProductQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductQuery, GetProductQueryVariables>(
    GetProductDocument,
    options
  );
}
export type GetProductQueryHookResult = ReturnType<typeof useGetProductQuery>;
export type GetProductLazyQueryHookResult = ReturnType<
  typeof useGetProductLazyQuery
>;
export type GetProductQueryResult = Apollo.QueryResult<
  GetProductQuery,
  GetProductQueryVariables
>;
export const GetPageDocument = gql`
  query GetPage(
    $slug: String
    $position: String
    $name: String
    $locale: String
  ) {
    pageContent(slug: $slug, position: $position, name: $name, locale: $locale)
  }
`;

/**
 * __useGetPageQuery__
 *
 * To run a query within a React component, call `useGetPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPageQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      position: // value for 'position'
 *      name: // value for 'name'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetPageQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPageQuery, GetPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPageQuery, GetPageQueryVariables>(
    GetPageDocument,
    options
  );
}
export function useGetPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPageQuery, GetPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPageQuery, GetPageQueryVariables>(
    GetPageDocument,
    options
  );
}
export type GetPageQueryHookResult = ReturnType<typeof useGetPageQuery>;
export type GetPageLazyQueryHookResult = ReturnType<typeof useGetPageLazyQuery>;
export type GetPageQueryResult = Apollo.QueryResult<
  GetPageQuery,
  GetPageQueryVariables
>;
export const GetCustomerDocument = gql`
  query GetCustomer {
    myCustomer {
      ...Customer
    }
  }
  ${CustomerFragmentDoc}
`;

/**
 * __useGetCustomerQuery__
 *
 * To run a query within a React component, call `useGetCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCustomerQuery,
    GetCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCustomerQuery, GetCustomerQueryVariables>(
    GetCustomerDocument,
    options
  );
}
export function useGetCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomerQuery,
    GetCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCustomerQuery, GetCustomerQueryVariables>(
    GetCustomerDocument,
    options
  );
}
export type GetCustomerQueryHookResult = ReturnType<typeof useGetCustomerQuery>;
export type GetCustomerLazyQueryHookResult = ReturnType<
  typeof useGetCustomerLazyQuery
>;
export type GetCustomerQueryResult = Apollo.QueryResult<
  GetCustomerQuery,
  GetCustomerQueryVariables
>;
export const SignInCustomerDocument = gql`
  query SignInCustomer(
    $email: String!
    $password: String!
    $anonymousCartId: String
    $rememberMe: Boolean
  ) {
    signInCustomer(
      email: $email
      password: $password
      anonymousCartId: $anonymousCartId
      rememberMe: $rememberMe
    ) {
      ...Customer
    }
  }
  ${CustomerFragmentDoc}
`;

/**
 * __useSignInCustomerQuery__
 *
 * To run a query within a React component, call `useSignInCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignInCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignInCustomerQuery({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      anonymousCartId: // value for 'anonymousCartId'
 *      rememberMe: // value for 'rememberMe'
 *   },
 * });
 */
export function useSignInCustomerQuery(
  baseOptions: Apollo.QueryHookOptions<
    SignInCustomerQuery,
    SignInCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SignInCustomerQuery, SignInCustomerQueryVariables>(
    SignInCustomerDocument,
    options
  );
}
export function useSignInCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SignInCustomerQuery,
    SignInCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SignInCustomerQuery, SignInCustomerQueryVariables>(
    SignInCustomerDocument,
    options
  );
}
export type SignInCustomerQueryHookResult = ReturnType<
  typeof useSignInCustomerQuery
>;
export type SignInCustomerLazyQueryHookResult = ReturnType<
  typeof useSignInCustomerLazyQuery
>;
export type SignInCustomerQueryResult = Apollo.QueryResult<
  SignInCustomerQuery,
  SignInCustomerQueryVariables
>;
export const SignOutCustomerDocument = gql`
  query SignOutCustomer {
    signOutCustomer
  }
`;

/**
 * __useSignOutCustomerQuery__
 *
 * To run a query within a React component, call `useSignOutCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignOutCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignOutCustomerQuery({
 *   variables: {
 *   },
 * });
 */
export function useSignOutCustomerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SignOutCustomerQuery,
    SignOutCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SignOutCustomerQuery, SignOutCustomerQueryVariables>(
    SignOutCustomerDocument,
    options
  );
}
export function useSignOutCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SignOutCustomerQuery,
    SignOutCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SignOutCustomerQuery,
    SignOutCustomerQueryVariables
  >(SignOutCustomerDocument, options);
}
export type SignOutCustomerQueryHookResult = ReturnType<
  typeof useSignOutCustomerQuery
>;
export type SignOutCustomerLazyQueryHookResult = ReturnType<
  typeof useSignOutCustomerLazyQuery
>;
export type SignOutCustomerQueryResult = Apollo.QueryResult<
  SignOutCustomerQuery,
  SignOutCustomerQueryVariables
>;
export const SignUpCustomerDocument = gql`
  mutation signUpCustomer(
    $customerData: SignUpCustomerData!
    $anonymousCartId: String
    $rememberMe: Boolean
    $locale: String
    $currencyCode: String
  ) {
    signUpCustomer(
      customerData: $customerData
      anonymousCartId: $anonymousCartId
      rememberMe: $rememberMe
      locale: $locale
      currencyCode: $currencyCode
    ) {
      ...Customer
    }
  }
  ${CustomerFragmentDoc}
`;
export type SignUpCustomerMutationFn = Apollo.MutationFunction<
  SignUpCustomerMutation,
  SignUpCustomerMutationVariables
>;

/**
 * __useSignUpCustomerMutation__
 *
 * To run a mutation, you first call `useSignUpCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpCustomerMutation, { data, loading, error }] = useSignUpCustomerMutation({
 *   variables: {
 *      customerData: // value for 'customerData'
 *      anonymousCartId: // value for 'anonymousCartId'
 *      rememberMe: // value for 'rememberMe'
 *      locale: // value for 'locale'
 *      currencyCode: // value for 'currencyCode'
 *   },
 * });
 */
export function useSignUpCustomerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignUpCustomerMutation,
    SignUpCustomerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SignUpCustomerMutation,
    SignUpCustomerMutationVariables
  >(SignUpCustomerDocument, options);
}
export type SignUpCustomerMutationHookResult = ReturnType<
  typeof useSignUpCustomerMutation
>;
export type SignUpCustomerMutationResult =
  Apollo.MutationResult<SignUpCustomerMutation>;
export type SignUpCustomerMutationOptions = Apollo.BaseMutationOptions<
  SignUpCustomerMutation,
  SignUpCustomerMutationVariables
>;
export const UpdateCustomerDocument = gql`
  mutation UpdateCustomer($actions: [CustomerUpdateAction]!) {
    batchUpdateMyCustomer(actions: $actions) {
      ...Customer
    }
  }
  ${CustomerFragmentDoc}
`;
export type UpdateCustomerMutationFn = Apollo.MutationFunction<
  UpdateCustomerMutation,
  UpdateCustomerMutationVariables
>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      actions: // value for 'actions'
 *   },
 * });
 */
export function useUpdateCustomerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCustomerMutation,
    UpdateCustomerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCustomerMutation,
    UpdateCustomerMutationVariables
  >(UpdateCustomerDocument, options);
}
export type UpdateCustomerMutationHookResult = ReturnType<
  typeof useUpdateCustomerMutation
>;
export type UpdateCustomerMutationResult =
  Apollo.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = Apollo.BaseMutationOptions<
  UpdateCustomerMutation,
  UpdateCustomerMutationVariables
>;
export const GetOrderListDocument = gql`
  query GetOrderList($locale: String) {
    myOrders(locale: $locale) {
      ...OrderList
    }
  }
  ${OrderListFragmentDoc}
`;

/**
 * __useGetOrderListQuery__
 *
 * To run a query within a React component, call `useGetOrderListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderListQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetOrderListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOrderListQuery,
    GetOrderListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrderListQuery, GetOrderListQueryVariables>(
    GetOrderListDocument,
    options
  );
}
export function useGetOrderListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrderListQuery,
    GetOrderListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrderListQuery, GetOrderListQueryVariables>(
    GetOrderListDocument,
    options
  );
}
export type GetOrderListQueryHookResult = ReturnType<
  typeof useGetOrderListQuery
>;
export type GetOrderListLazyQueryHookResult = ReturnType<
  typeof useGetOrderListLazyQuery
>;
export type GetOrderListQueryResult = Apollo.QueryResult<
  GetOrderListQuery,
  GetOrderListQueryVariables
>;
export const GetCategorySitemapDocument = gql`
  query GetCategorySitemap($locale: String) {
    categoryList(locale: $locale) {
      result {
        slug
      }
    }
  }
`;

/**
 * __useGetCategorySitemapQuery__
 *
 * To run a query within a React component, call `useGetCategorySitemapQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategorySitemapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategorySitemapQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetCategorySitemapQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCategorySitemapQuery,
    GetCategorySitemapQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCategorySitemapQuery,
    GetCategorySitemapQueryVariables
  >(GetCategorySitemapDocument, options);
}
export function useGetCategorySitemapLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCategorySitemapQuery,
    GetCategorySitemapQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCategorySitemapQuery,
    GetCategorySitemapQueryVariables
  >(GetCategorySitemapDocument, options);
}
export type GetCategorySitemapQueryHookResult = ReturnType<
  typeof useGetCategorySitemapQuery
>;
export type GetCategorySitemapLazyQueryHookResult = ReturnType<
  typeof useGetCategorySitemapLazyQuery
>;
export type GetCategorySitemapQueryResult = Apollo.QueryResult<
  GetCategorySitemapQuery,
  GetCategorySitemapQueryVariables
>;
export const GetCmsSitemapDocument = gql`
  query GetCmsSitemap($slugs: String, $locale: String) {
    pageMetadata(slugs: $slugs, locale: $locale) {
      slug
    }
  }
`;

/**
 * __useGetCmsSitemapQuery__
 *
 * To run a query within a React component, call `useGetCmsSitemapQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCmsSitemapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCmsSitemapQuery({
 *   variables: {
 *      slugs: // value for 'slugs'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetCmsSitemapQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCmsSitemapQuery,
    GetCmsSitemapQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCmsSitemapQuery, GetCmsSitemapQueryVariables>(
    GetCmsSitemapDocument,
    options
  );
}
export function useGetCmsSitemapLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCmsSitemapQuery,
    GetCmsSitemapQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCmsSitemapQuery, GetCmsSitemapQueryVariables>(
    GetCmsSitemapDocument,
    options
  );
}
export type GetCmsSitemapQueryHookResult = ReturnType<
  typeof useGetCmsSitemapQuery
>;
export type GetCmsSitemapLazyQueryHookResult = ReturnType<
  typeof useGetCmsSitemapLazyQuery
>;
export type GetCmsSitemapQueryResult = Apollo.QueryResult<
  GetCmsSitemapQuery,
  GetCmsSitemapQueryVariables
>;
export const GetProductSitemapDocument = gql`
  query GetProductSitemap(
    $locale: String
    $page: Int
    $perPage: Int
    $currencyCode: String
  ) {
    productList(
      filter: {}
      locale: $locale
      page: $page
      perPage: $perPage
      currencyCode: $currencyCode
    ) {
      products {
        slug
      }
      metadata {
        count
        offset
        total
      }
    }
  }
`;

/**
 * __useGetProductSitemapQuery__
 *
 * To run a query within a React component, call `useGetProductSitemapQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductSitemapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductSitemapQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      currencyCode: // value for 'currencyCode'
 *   },
 * });
 */
export function useGetProductSitemapQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProductSitemapQuery,
    GetProductSitemapQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProductSitemapQuery,
    GetProductSitemapQueryVariables
  >(GetProductSitemapDocument, options);
}
export function useGetProductSitemapLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductSitemapQuery,
    GetProductSitemapQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProductSitemapQuery,
    GetProductSitemapQueryVariables
  >(GetProductSitemapDocument, options);
}
export type GetProductSitemapQueryHookResult = ReturnType<
  typeof useGetProductSitemapQuery
>;
export type GetProductSitemapLazyQueryHookResult = ReturnType<
  typeof useGetProductSitemapLazyQuery
>;
export type GetProductSitemapQueryResult = Apollo.QueryResult<
  GetProductSitemapQuery,
  GetProductSitemapQueryVariables
>;
