import React, { useContext } from "react";
import {
  AddLineItemAction,
  Cart,
  ChangeLineItemQuantityAction,
  RemoveLineItemAction,
  SetBillingAddressAction,
  SetCustomerEmailAction,
  SetShippingAddressAction,
} from "@graphql/generated/graphql";
import { DataContext } from "@components/provider/DataContext";

export type UseCart = {
  cartData: Cart;
  addLineItemAction: ({ sku, quantity }: AddLineItemAction) => Promise<boolean>;
  removeLineItemAction: ({
    lineItemId,
  }: RemoveLineItemAction) => Promise<boolean>;
  changeLineItemQuantityAction: ({
    lineItemId,
    quantity,
  }: ChangeLineItemQuantityAction) => Promise<boolean>;
  setCartAddressAction: ({
    setBillingAddressAction,
    setShippingAddressAction,
    setCustomerEmailAction = null,
  }: {
    setBillingAddressAction: SetBillingAddressAction;
    setShippingAddressAction: SetShippingAddressAction;
    setCustomerEmailAction?: SetCustomerEmailAction | null;
  }) => Promise<boolean>;
  placeOrderAction: () => Promise<boolean>;
};

const defaultCartData: Cart = {
  cartItems: [],
  id: "",
  totalGrossPriceInCent: 0,
  totalNetPriceInCent: 0,
  version: 0,
};

const defaultCartAction = async () => {
  return false;
};

export const defaultUseCart: UseCart = {
  cartData: defaultCartData,
  addLineItemAction: defaultCartAction,
  removeLineItemAction: defaultCartAction,
  changeLineItemQuantityAction: defaultCartAction,
  setCartAddressAction: defaultCartAction,
  placeOrderAction: defaultCartAction,
};

export const useCart = (): UseCart => {
  const context = useContext(DataContext);
  if (!context.cart)
    throw new Error("Expected to be wrapped in a DataProvider");
  return context.cart;
};
