import { useCallback } from "react";
import { useApolloClient } from "@apollo/client";
import {
  Customer,
  CustomerUpdateAction,
  UpdateCustomerDocument,
  UpdateCustomerMutation,
  UpdateCustomerMutationVariables,
} from "@graphql/generated/graphql";
import { useSession } from "next-auth/react";

type UseUpdateCustomer = {
  updateCustomer: (
    action: Array<CustomerUpdateAction>
  ) => Promise<Customer | null>;
};

export const useUpdateCustomer = (): UseUpdateCustomer => {
  const client = useApolloClient();
  const { data: session } = useSession();

  const update = useCallback(
    async (action: Array<CustomerUpdateAction>): Promise<Customer | null> => {
      if (!session) {
        throw new Error(
          "Failed to update the customer data. Authorization is required!"
        );
      }
      const { data } = await client.mutate<
        UpdateCustomerMutation,
        UpdateCustomerMutationVariables
      >({
        mutation: UpdateCustomerDocument,
        variables: {
          actions: action,
        },
      });
      if (data?.batchUpdateMyCustomer) {
        return data.batchUpdateMyCustomer;
      }
      return null;
    },
    [client, session]
  );

  return {
    updateCustomer: update,
  };
};
