import React, { useContext } from "react";
import { DataContext } from "@components/provider/DataContext";

export type UsePayment = {
  paymentMethod: string | undefined;
  setPaymentMethodAction: (paymentMethod: string | undefined) => void;
};

export const defaultUsePayment: UsePayment = {
  paymentMethod: undefined,
  setPaymentMethodAction: () => {},
};

export const usePayment = (): UsePayment => {
  const context = useContext(DataContext);
  if (!context.payment)
    throw new Error("Expected to be wrapped in a DataProvider");
  return context.payment;
};
