import React, { createContext } from "react";
import { defaultUseCart, UseCart } from "@components/provider/UseCart";
import {
  defaultUseCustomer,
  UseCustomer,
} from "@components/provider/UseCustomer";
import { defaultUsePayment, UsePayment } from "@components/provider/UsePayment";

export type DataContextType = {
  cart: UseCart;
  customer: UseCustomer;
  payment: UsePayment;
};

export const defaultContext: DataContextType = {
  cart: defaultUseCart,
  customer: defaultUseCustomer,
  payment: defaultUsePayment,
};

export const DataContext = createContext<DataContextType>(defaultContext);
