import {ApolloClient, ApolloLink, from, InMemoryCache, NormalizedCacheObject,} from "@apollo/client";
import {BatchHttpLink} from "@apollo/client/link/batch-http";
import {setContext} from "@apollo/client/link/context";
import {onError} from "@apollo/client/link/error";
import xfetch from "cross-fetch";
import env from "@beam-australia/react-env";

function createApolloClient(token: string | null = null) {
  const batchHttpLink: BatchHttpLink = new BatchHttpLink({
    uri: `${env("API_GRAPHQL_ENDPOINT")}`,
    credentials: "include",
    batchMax: 5, // No more than 5 operations per batch
    batchInterval: 20, // Wait no more than 20ms after first batched operation
  });

  const cleanTypeName = new ApolloLink((operation, forward) => {
    if (operation.variables) {
      const omitTypename = (key: string, value: any) =>
        key === "__typename" ? undefined : value;
      operation.variables = JSON.parse(
        JSON.stringify(operation.variables),
        omitTypename
      );
    }
    return forward(operation).map((data) => {
      return data;
    });
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        "x-xsrf-token": token ? token : "",
      },
      fetch: xfetch,
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    if (networkError) console.error(`[Network error]: ${networkError}`);
  });

  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: from([cleanTypeName, errorLink, authLink, batchHttpLink]),
    cache: new InMemoryCache(),
  });
}

export function getClient(
  token: string | null = null
): ApolloClient<NormalizedCacheObject> {
  return createApolloClient(token);
}
