import { useCallback } from "react";
import { useApolloClient } from "@apollo/client";
import {
  Customer,
  SignUpCustomerData,
  SignUpCustomerDocument,
  SignUpCustomerMutation,
  SignUpCustomerMutationVariables,
} from "@graphql/generated/graphql";
import config from "@lib/config";

type UseSignUpCustomer = {
  signUpCustomer: (
    customerData: SignUpCustomerData
  ) => Promise<Customer | null>;
};

export const useSignUpCustomer = (): UseSignUpCustomer => {
  const client = useApolloClient();

  const signUp = useCallback(
    async (customerData: SignUpCustomerData): Promise<Customer | null> => {
      const { data } = await client.mutate<
        SignUpCustomerMutation,
        SignUpCustomerMutationVariables
      >({
        mutation: SignUpCustomerDocument,
        variables: {
          customerData: customerData,
          rememberMe: false,
          locale: config.defaultLanguageCode,
          currencyCode: config.defaultCurrencyCode,
        },
      });
      if (data?.signUpCustomer) {
        return data.signUpCustomer;
      }
      return null;
    },
    [client]
  );

  return { signUpCustomer: signUp };
};
