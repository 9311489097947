import React, { FC, Fragment, ReactElement, ReactNode } from "react";
import type { AppProps } from "next/app";
import type { Session } from "next-auth";
import { NextPage } from "next";
import { useRouter } from "next/router";
import { ApolloProvider } from "@apollo/client";
import { SessionProvider, useSession } from "next-auth/react";
import DataProvider from "@components/provider/DataProvider";
import ErrorBoundary from "@components/layout/ErrorBoundary";
import { DataContext } from "@components/provider/DataContext";
import { getClient } from "@graphql/client";

import { appWithTranslation } from "next-i18next";
// Import global styles (tailwind)
import "@assets/styles/globals.css";

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps<{ session: Session }> & {
  Component: NextPageWithLayout;
};

type Props = {
  children: ReactNode | ReactElement;
};

const AppComponent: FC<Props> = ({ children }) => {
  const { data: session } = useSession();

  return (
    <ErrorBoundary>
      <ApolloProvider client={getClient(session?.user.token)}>
        <DataProvider context={DataContext}>{children}</DataProvider>
      </ApolloProvider>
    </ErrorBoundary>
  );
};

const App = ({
  Component,
  pageProps: { session, ...pageProps },
}: AppPropsWithLayout) => {
  const router = useRouter();
  const getLayout = Component.getLayout ?? ((page) => page);
  const layout = getLayout(<Component {...pageProps} />);

  return (
    <SessionProvider session={session}>
      <AppComponent>
        <Fragment key={router.asPath}>{layout}</Fragment>
      </AppComponent>
    </SessionProvider>
  );
};

export default appWithTranslation(App);
