import { useCallback } from "react";
import { useApolloClient } from "@apollo/client";
import config from "@lib/config";
import { useCookie } from "@lib/hooks/useCookie";
import { useSession } from "next-auth/react";
import isString from "lodash/isString";
import {
  Cart,
  CartUpdateAction,
  UpdateCartDocument,
  UpdateCartMutation,
  UpdateCartMutationVariables,
} from "@graphql/generated/graphql";

type UseUpdateCart = {
  updateCart: (action: Array<CartUpdateAction>) => Promise<Cart | null>;
};

export const useUpdateCart = (): UseUpdateCart => {
  const client = useApolloClient();
  const { data: session } = useSession();
  const { getCookie, setCookie } = useCookie({ key: "cartId" });

  const init = useCallback(
    (id: string): void => {
      const cartId = getCookie();
      if (session || (isString(cartId) && cartId !== "")) {
        return;
      }
      setCookie(id, {
        sameSite: "lax",
        expires: new Date(new Date().getTime() + config.cartExpiration),
      });
    },
    [getCookie, setCookie, session]
  );

  const update = useCallback(
    async (action: Array<CartUpdateAction>): Promise<Cart | null> => {
      const { data } = await client.mutate<
        UpdateCartMutation,
        UpdateCartMutationVariables
      >({
        mutation: UpdateCartDocument,
        variables: {
          actions: action,
          locale: config.defaultLanguageCode,
          currencyCode: config.defaultCurrencyCode,
        },
      });
      if (data?.batchUpdateMyCart) {
        init(data.batchUpdateMyCart.id);
        return data.batchUpdateMyCart;
      }
      return null;
    },
    [client, init]
  );

  return {
    updateCart: update,
  };
};
